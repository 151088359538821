<template>
  <div class="user-info">
    <div class="user-info-self">
      <div class="user-info-base">
        <div class="user-info-content">
          <template v-if="isSelf">
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userBaseInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userBaseInfo.realname}}<i
                class="el-icon-edit"
                @click="showDialog = true"
              ></i></p>
          </template>
          <template v-else>
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userInfo.realname}}</p>
          </template>
          <div class="desc">
            <ul>
              <li><span>累计学习时长</span><label>{{userInfo.studyTime}}h</label></li>
              <li><span>累计代码行数</span><label>{{userInfo.codeLines}}</label></li>
              <li><span>发布作品数量</span><label>{{userInfo.workNum}}</label></li>
              <li><span>作品获赞次数</span><label>{{userInfo.starNum}}</label></li>
              <li><span>作品获浏览量</span><label>{{userInfo.viewNum}}</label></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info-body">
      <div class="btn-title">
        <!-- <button @click="changeWorkType('all')" :class="workType == 'all'?'actived':''">全部作品</button> -->
        <!-- <div class="curriculum-block">
             <el-switch
          @change="changeWorkType('all')"
            v-model="allWork"
            active-color="#f59a23">
          </el-switch>
          <span :class="allWork == true?'curriculum-span-light':'curriculum-span'">全部作品</span>
          </div> -->
        <!-- <div style="float:right;height:42px;margin-top:-16px">
             <div class="curriculum-block">
             <el-switch
          @change="changeWorkType('collected')"
            v-model="collectWork"
            active-color="#f59a23">
          </el-switch>
          <span :class="collectWork == true?'curriculum-span-light':'curriculum-span'">收藏的作品</span>
            </div>   
            <div class="curriculum-block">
             <el-switch
          @change="changeWorkType('evaluated')"
            v-model="evalWork"
            active-color="#f59a23">
          </el-switch>
          <span :class="evalWork == true?'curriculum-span-light':'curriculum-span'">教师评价的作品</span>
            </div>  
          </div> -->

        <!-- <button @click="changeWorkType('collected')" :class="workType == 'collected'?'actived':''">收藏的作品</button>
      <button @click="changeWorkType('evaluated')" :class="workType == 'evaluated'?'actived':''">教师评价的作品</button> -->
      </div>
      <template>
        <div class="user-info-program-item">
          <div class="search">
            <div class="el-select">
              <el-select
                size="small"
                clearable
                v-model="searchParams.fileType"
                placeholder="请选择实验室"
                style="margin-right: 10px;"
                @change="getWorksData"
              >
                <el-option
                  v-for="item2 in roomList"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="el-select">
              <el-select
                size="small"
                clearable
                v-model="searchParams.order"
                placeholder="请选择排序"
                style="margin-right: 10px;"
                @change="getWorksData"
              >
                <el-option
                  v-for="item2 in sortTypeList"
                  :key="item2.value"
                  :label="item2.label"
                  :value="item2.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="el-select">
              <el-select
                size="small"
                clearable
                v-model="searchParams.collect_type"
                placeholder="请选择作品是否收藏"
                style="margin-right: 10px;"
                @change="getWorksData"
              >
                <!-- <el-option
                label="未收藏"
                value="0">
              </el-option> -->
                <el-option
                  label="已收藏"
                  value="1"
                >
                </el-option>
              </el-select>
            </div>
            <div class="el-select">
              <el-select
                size="small"
                clearable
                v-model="searchParams.evaluate"
                placeholder="请选择教师是否评价"
                style="margin-right: 10px;"
                @change="getWorksData"
              >
                <el-option
                  label="是"
                  value="1"
                >
                </el-option>
                <el-option
                  label="否"
                  value="0"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-child">
              <el-input
                size="small"
                @clear="getWorksData"
                clearable
                v-model="searchParams.workName"
                placeholder="作品名称"
                style="width: 200px; margin-right: 10px;"
                @change="getWorksData(true)"
              />
              <el-button
                size="small"
                type="primary"
                @click="getWorksData(true)"
              >搜索</el-button>
            </div>
          </div>
          <p
            class="no-data"
            v-if="dataList.length==0"
          >暂无数据！</p>
          <ProgramList
            :datas="dataList"
            :type="2"
            @success="getUserInfo"
          ></ProgramList>
          <div class="content-pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="searchParams.pageNo"
              :page-size="searchParams.pageSize"
              layout="total, prev, pager, next,jumper"
              :total="dataTotal"
            >
            </el-pagination>
          </div>

          <!-- <div class="page" v-if="dataList.length !=0 && dataList.length%10 == 0" @click="changePage(item.type, item.page+1)">点击加载更多</div> -->
        </div>
      </template>
    </div>
    <el-dialog
      title="编辑昵称"
      width="400px"
      :visible.sync="showDialog"
      :show-close="false"
      @closed="closeDialog"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="changeRules"
      >
        <el-form-item
          label="新昵称"
          :label-width="'80px'"
          prop="nickname"
        >
          <el-input
            v-model="dialogForm.nickname"
            autocomplete="off"
            placeholder="请输入昵称（6个汉字以内）"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="postNickname"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 1是作品 2是课程 3是单元
const WORK_TYPE = 1;
const COURSE_TYPE = 2;
const COURSE_UNIT_TYPE = 3;
import ProgramList from '@/components/ProgramList';
import Curriculums from '@/components/Curriculums';
import { getWorks, getCollects, getUserInfo, changeNickname } from "@/api/user"
import Vue from 'vue'
import { LABS } from '@/utils/page-resource';
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';
export default {
  name: 'User-Info',
  components: {
    ProgramList,
    Curriculums
  },
  data() {
    return {
      allWork: true,
      collectWork: false,
      evalWork: false,
      workType: 'all',
      searchParams: {
        fileType: null,
        order: null,
        workName: null,
        hasEvalute: false,
        collect_type: null,
        pageSize: 10,
        pageNo: 1
      },
      showDialog: false,
      dialogForm: {
        nickname: ''
      },
      isSelf: !this.$route.query.uid || this.$route.query.uid == Vue.ls.get('Login_Userid'),
      uid: this.$route.query.uid,
      userInfo: {
        name: '',
        avatar: '',
        studyTime: 0,
        codeLines: 0,
        workNum: 0,
        starNum: 0,
        viewNum: 0,
      },
      dataList: [],
      dataTotal: 0,
      changeRules: {
        nickname: [
          {
            validator: (rule, value, callback) => {
              if (value == '') {
                callback(new Error('请输入昵称'));
              } else if (!/^[\u4e00-\u9fa5]{1,6}$/.test(value)) {
                callback(new Error('请输入最多6个中文'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
      },
      roomList: [
        ...LABS.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
      ],
      sortTypeList: [
        {
          value: 'hotNum',
          label: '按最受欢迎排序',
        },
        {
          value: 'createTime',
          label: '按最新时间排序',
        }
      ],
      userBaseInfo: {}
    };
  },
  watch: {
    $route(newRoute) {
      this.uid = newRoute.query.uid,
        this.init();
    },
    showDialog(value) {
      if (value) {
        this.dialogForm.nickname = this.userInfo.realname;
      }
    }
  },
  mounted() {
    this.searchParams.uid = this.uid || Vue.ls.get('Login_Userid');
    this.init();
    this.workType = 'all';
  },
  computed: {
    // userBaseInfo: function () {
    //   return getUserBaseInfo();
    // },
  },
  methods: {
    init() {
      if (!this.uid && !Vue.ls.get('Login_Userid')) {
        this.$router.push(`/login?redirect_uri=${location.href}`)
        // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      } else {
        this.searchParams.pageNo = 1;
        this.getUserInfo();
        this.userBaseInfo = getUserBaseInfo()
        this.getWorksData();
      }
    },
    changeWorkType(type) {
      if (type == 'evaluated') {
        if (this.evalWork == true) {
          this.allWork = !this.evalWork;
          this.collectWork = !this.evalWork;
          this.workType = 'evaluated';
        } else {
          this.allWork = true;
          this.workType = 'all';
        }
      } else {
        if (type == 'all') {
          this.workType = type;
          this.allWork = true;
          this.evalWork = !this.allWork;
          this.collectWork = !this.allWork;
        } else {
          if (this.collectWork == true) {
            this.evalWork = !this.collectWork;
            this.allWork = !this.collectWork;
            this.workType = 'collected';
          } else {
            this.allWork = true;
            this.workType = 'all';
          }
        }
      }
      this.searchParams.hasEvalute = this.evalWork;
      this.getWorksData();
    },
    getWorksData() {
      this.dataList = [];
      if (this.searchParams.collect_type == WORK_TYPE) {
        if (!Vue.ls.get('Login_Userid')) {
          return;
        }
        getCollects(this.searchParams).then(res => {
          if (res.success) {
            let data = res.result;
            this.dataTotal = data.total;
            this.dataList.push(...data.records.map(item => {
              return Object.assign({}, item, { star: false, collect: false, tags: [item.fileType_dictText, item.tagDifficulty] })
            }));
          }
        })
      } else {
        getWorks(this.searchParams).then(res => {
          if (res.success) {
            let data = res.result;
            this.dataTotal = data.total;
            this.dataList.push(...data.records.map(item => {
              return Object.assign({}, item, { star: false, collect: false, tags: [item.fileType_dictText, item.tagDifficulty] })
            }));
          }
        })
      }
    },
    handleCurrentChange(val) {
      this.searchParams.pageNo = val;
      this.getWorksData();
    },
    postNickname() {
      this.$refs.dialogForm.validate(isOk => {
        if (isOk) {
          changeNickname(this.dialogForm).then(res => {
            if (res.success) {
              this.$notify({
                title: '系统提示',
                message: res.message || '修改昵称成功',
                type: 'success'
              });
              // this.userBaseInfo.realname = this.dialogForm.nickname
              // this.userInfo.realname = this.dialogForm.nickname
              // this.showDialog = false
              this.$store.dispatch('ChangeNickname', this.dialogForm.nickname);
              setTimeout(() => {
                location.reload();
              }, 1000)
            } else {
              this.$notify({
                title: '系统提示',
                message: res.message || '修改失败：未知错误',
                type: 'warning'
              });
            }
          })
        }
      });
    },
    closeDialog() {
      // console.log(this.$refs.dialogForm)
      this.$refs.dialogForm.resetFields();
    },
    getUserInfo() {
      getUserInfo({ uid: this.uid || Vue.ls.get('Login_Userid') }).then(res => {
        if (res.success) {
          this.userInfo = res.result;
        }
      })
    }
  },
};
</script>

<style lang="scss">
.user-info .el-form-item__error {
  white-space: initial;
}
</style>
<style lang="scss" scoped>
@import '~@/assets/css/views/user-info.scss';
.el-select {
  display: inline-block;
  position: relative;
}
.search-child {
  display: inline-block;
  vertical-align: top;
  float: right;
}
.curriculum-block {
  margin: 20px 0 20px 15px;
  background-color: #eff3f7;
  display: inline-block;
  padding: 1px 5px 3px 5px;
  border-radius: 5px;
  .curriculum-span {
    color: #d5d5d5 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    margin-left: 7px;
  }
  .curriculum-span-light {
    color: #f59a23 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    margin-left: 7px;
  }
}
.btn-title {
  margin: 20px 0 10px 0;
  button {
    margin-right: 10px;
    color: #fff;
    background-color: #0081ef;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 140px;
    height: 40px;
    line-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #0081ef;
    line-height: 26px;
    -webkit-transition: all 0.3s;
    font-weight: bold;
    &:hover {
      border: 2px solid #f59a23;
      background-color: #f59a23;
    }
  }
}
.actived {
  border: 2px solid #f59a23 !important;
  background-color: #f59a23 !important;
}
.content-pagination {
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: right;
  text-align: right;
}
</style>
